import React, { Component } from 'react';
import styled from 'styled-components';
import Modal from '../containers/common/Modal';
import StyledTappable from './common/StyledTappable';
import { connect } from 'react-redux';
import { Text, BoldText } from './common/Text';
import { PrimaryButton } from './common/Buttons';
import { FaTimes } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import { updateMemberInfo, getUserMembers } from '../services';
import { resetActiveConsult } from '../actions';
import moment from 'moment';
const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`;

const ModalBody = styled.div`
  display: flex;
  flex: 1;
  max-width: 30%;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 1.125rem 1.25rem 1.875rem 1.25rem;
  border-radius: 0.5rem;
  margin: 0 1rem;
  text-align: center;
`;

const CloseContainer = styled(StyledTappable)`
  /* align-self: flex-end; */
`;

const CrossIcon = styled(FaTimes)`
  font-size: 2rem;
  font-weight: normal;
`;

const ModalHeader = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  padding: 0rem 1.25rem 1.875rem 1.25rem;
`;

const HeaderText = styled(BoldText)`
  font-size: 2rem;
`;

const ButtonsComponent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Body = styled.div`
  display: flex;
  flex: 1;
  align-self: stretch;
  /* border: 4mm solid rgb(170, 50, 20, 0.6); */
  flex-direction: column;
`;

class UpdateInfoModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      showAlert: false,
      alertText: '',
      alertType: 'success',
      name: '',
      dob: null,
      gender: '',
      relation: '',
      proposalNo: '',
      proposerEmail: "",
      proposerAddress: "",
      isProposerDetails: false,
    };
    this.state.name = props.relative.name;
    console.log(props.relative.dob);
    if (props.relative.dob === '0000-00-00') {
      if (this.props.insurer !== "mchi") {
        this.state.dob = new Date();
      }
    } else {
      this.state.dob = new Date(props.relative.dob);
    }
    console.log(this.state.dob);
    this.state.gender = props.relative.gender;
    this.state.proposalNo = props.relative.proposalNumber;
    this.state.proposerEmail = props.relative.email;
    this.state.proposerAddress = props.relative.address;
    this.state.isProposerDetails = props.relative.isProposerDetails;
    if (props.relative.isProposerDetails) {
      this.state.relation = 'Self';
    } else {
      this.state.proposalNo = props.relative.proposalNumber;
      this.state.relation = props.relative.relation;
    }
  }

  componentDidMount() {
    console.log('here');
  }

  setName = (ev) => {
    const value = ev.target.value;
    if (value) {
      this.setState({
        name: value,
      });
    } else {
      this.setState({
        name: '',
      });
    }
  };
  setProposalNo = (ev) => {
    const value = ev.target.value;
    if (value) {
      this.setState({
        proposalNo: value,
      });
    } else {
      this.setState({
        proposalNo: '',
      });
    }
  };

  setProposerEmail = (ev) => {
    const value = ev.target.value;
    if (value) {
      this.setState({
        proposerEmail: value,
      });
    } else {
      this.setState({
        proposerEmail: '',
      });
    }
  };

  setProposerAddress = (ev) => {
    const value = ev.target.value;
    if (value) {
      this.setState({
        proposerAddress: value,
      });
    } else {
      this.setState({
        proposerAddress: '',
      });
    }
  };

  onDobChange = (date) => {
    this.setState({
      dob: date,
    });
  };

  addCase = () => {
    const { name, gender, dob, relation, proposalNo, proposerEmail, proposerAddress } = this.state;
    let newdob = new Date(dob);
    const userId = this.props.userId;
    newdob = new Date(newdob.setHours(newdob.getHours() + 5));
    newdob = newdob.setMinutes(newdob.getMinutes() + 30);
    this.setState({
      loading: true,
      showAlert: false,
    });
    if (!dob) {
      newdob = "0000-00-00"
    } else {
      newdob = new Date(newdob);
      newdob = moment(newdob).format('YYYY-MM-DD');
    }
    const memberInfo = {
      ...this.props.relative,
      dob: newdob,
      gender,
      name,
      relation,
      proposalNumber: proposalNo,
      proposerEmail: proposerEmail,
      proposerAddress: proposerAddress
    };
    const body = { memberInfo, userId };
    updateMemberInfo(body)
      .then((res) => {
        if (res.message === 'warning') {
          this.setState({
            alertText:
              res.errorMessage ||
              'Something went wrong. Please contact administrator',
            alertType: 'warning',
            showAlert: true,
          });
          return;
        }
        this.setState({
          alertText: 'updated successfully successfully',
          alertType: 'success',
          showAlert: true,
        });
        this.getUserMembers();
        return;
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          alertText:
            err.errorMessage || 'Something went wrong. Please try again later',
          alertType: 'danger',
          showAlert: true,
        });
      })
      .finally(() => {
        this.setState({ loading: false });
        // this.fetchConsults();
      });
  };

  getUserMembers = () => {
    //   getUserMembers(this.props.userId)
    //   .then(res=>{
    // this.props.fetchConsults();
    this.props.dispatch(resetActiveConsult());
    this.props.hideModal();
    //   })
  };

  render() {
    const {
      alertText,
      alertType,
      name,
      gender,
      showAlert,
      loading,
      dob,
      relation,
      proposalNo,
      proposerEmail,
      proposerAddress
    } = this.state;
    return (
      <Modal>
        <ModalContainer>
          <ModalBody>
            <ModalHeader>
              <HeaderText>Update Info</HeaderText>
              <ButtonsComponent>
                <CloseContainer onTap={this.props.hideModal}>
                  <CrossIcon />
                </CloseContainer>
              </ButtonsComponent>
            </ModalHeader>

            <Body>
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div
                      className={`alert collapse ${showAlert ? 'show' : ''} ${alertType === 'success'
                        ? 'alert-success'
                        : alertType === 'warning'
                          ? 'alert-warning'
                          : 'alert-danger'
                        }`}
                      role="alert"
                    >
                      {alertText}
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group row no-gutters">
                      <label
                        htmlFor="user-name-input"
                        className="col-sm-5 mt-3 col-form-label"
                      >
                        <Text fontSize="14px">Proposal Number</Text>
                      </label>
                      <div className="col-sm-7 mt-3">
                        <input
                          type="text"
                          className="form-control"
                          id="user-name-input"
                          value={proposalNo}
                          onChange={this.setProposalNo}
                        />
                      </div>
                      <label
                        htmlFor="user-name-input"
                        className="col-sm-5 mt-3 col-form-label"
                      >
                        <Text fontSize="14px">Name</Text>
                      </label>
                      <div className="col-sm-7 mt-3">
                        <input
                          type="text"
                          className="form-control"
                          id="user-name-input"
                          value={name}
                          onChange={this.setName}
                        />
                      </div>
                      {!this.state.isProposerDetails && (
                        <>
                          <label
                            htmlFor="user-name-input"
                            className="col-sm-5 mt-3 col-form-label"
                          >
                            <Text fontSize="14px">Gender</Text>
                          </label>
                          <div className="col-sm-7 mt-3">
                            <select
                              className="form-control"
                              value={gender}
                              onChange={(e) => {
                                this.setState({
                                  gender: e.target.value,
                                });
                              }}
                            >
                              <option value={'Male'}>Male</option>
                              <option value={'Female'}>Female</option>
                            </select>
                          </div>
                        </>
                      )}

                      {!this.state.isProposerDetails &&
                        this.props.insurer === 'abhi' ? (
                        <>
                          {' '}
                          <label
                            htmlFor="user-relation-input"
                            className="col-sm-5 mt-3 col-form-label"
                          >
                            <Text fontSize="14px">Relation</Text>
                          </label>
                          <div className="col-sm-7 mt-3">
                            <select
                              className="form-control"
                              value={relation}
                              onChange={(e) => {
                                this.setState({
                                  relation: e.target.value,
                                });
                              }}
                            >
                              <option value={'Brother'}>Brother</option>
                              <option value={'Brother-in-Law'}>
                                Brother-in-Law
                              </option>
                              <option value={'Daughter'}>Daughter</option>
                              <option value={'Daughter-in-Law'}>
                                Daughter-in-Law
                              </option>
                              <option value={'Dependent Daughter'}>
                                Dependent Daughter
                              </option>
                              <option value={'Dependent Son'}>
                                Dependent Son
                              </option>
                              <option value={'Father'}>Father</option>
                              <option value={'Father In law'}>
                                Father In law
                              </option>
                              <option value={'Father"s sister'}>
                                Father"s sister
                              </option>
                              <option value={'Grand-Mother'}>
                                Grand-Mother
                              </option>
                              <option value={'Grand-Father'}>
                                Grand-Father
                              </option>
                              <option value={'Mother'}>Mother</option>
                              <option value={'Mother In law'}>
                                Mother In law
                              </option>
                              <option value={'Nephew'}>Nephew</option>
                              <option value={'Niece'}>Niece</option>
                              <option value={'Self'}>Self</option>
                              <option value={'Sister'}>Sister</option>
                              <option value={'Sister-in-Law'}>
                                Sister-in-Law
                              </option>
                              <option value={'Son'}>Son</option>
                              <option value={'Spouse'}>Spouse</option>
                            </select>
                          </div>
                        </>
                      ) : (
                        ''
                      )}
                      {
                        (this.props.insurer != "mchi" || (this.props.insurer == "mchi" && !this.state.isProposerDetails)) && (
                          <>
                            <label
                              htmlFor="doctor-type-input"
                              className="col-sm-5 mt-3 col-form-label"
                            >
                              <Text fontSize="14px">Dob</Text>
                            </label>
                            <div className="col-sm-7 mt-3">
                              <DatePicker
                                selected={dob}
                                onChange={this.onDobChange}
                                dateFormat="yyyy-MM-dd"
                                className="newCaseInput"
                              />
                            </div>
                          </>
                        )
                      }
                      {this.state.isProposerDetails && (
                        <>
                          <label
                            htmlFor="user-name-input"
                            className="col-sm-5 mt-3 col-form-label"
                          >
                            <Text fontSize="14px">Email</Text>
                          </label>
                          <div className="col-sm-7 mt-3">
                            <input
                              type="text"
                              className="form-control"
                              id="user-name-input"
                              value={proposerEmail}
                              onChange={this.setProposerEmail}
                            />
                          </div>
                        </>
                      )}
                      {this.state.isProposerDetails && this.props.insurer == "mchi" && (
                        <>
                          <label
                            htmlFor="user-name-input"
                            className="col-sm-5 mt-3 col-form-label"
                          >
                            <Text fontSize="14px">Address</Text>
                          </label>
                          <div className="col-sm-7 mt-3">
                            <input
                              type="text"
                              className="form-control"
                              id="user-name-input"
                              value={proposerAddress}
                              onChange={this.setProposerAddress}
                            />
                          </div>
                        </>
                      )}
                    </div>
                    <div className="col-12 mt-4">
                      <PrimaryButton loading={loading} onTap={this.addCase}>
                        <BoldText fontSize="16px" color="white">
                          Update
                        </BoldText>
                      </PrimaryButton>
                    </div>
                  </div>
                </div>
              </div>
            </Body>
          </ModalBody>
        </ModalContainer>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  authToken: state.user.authToken,
  activeConsult: state.consults.activeConsult,
  insurer: state.user.insurer,
});

export default connect(mapStateToProps)(UpdateInfoModal);
