import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FaTimes } from 'react-icons/fa';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import styled from 'styled-components';

import ErrorModal from './containers/common/ErrorModal';
import Modal from './containers/common/Modal';
import LoginPage from './containers/LoginPage';
import DashboardHome from './containers/DashboardHome';
import OpsDashboard from './containers/OpsDashboard';
import VideoCallContainer from './containers/VideoCallContainer';
import StyledTappable from './components/common/StyledTappable';
import { BoldText, RegularText } from './components/common/Text';
import modalIcon from './images/common/paymentFail.svg';
import modalSuccessIcon from './images/common/purpleTick.svg';
import { fetchConsults } from './actions';

import { closeJoinMessage, hideError, hideSuccess, initiateVideoCall } from './actions/index';
import './App.css';
import 'react-table/react-table.css';

import 'react-datepicker/dist/react-datepicker.css';
import MasterAdmin from './containers/MasterAdmin';
import ViewSheets from './containers/ViewSheets';

const OuterContainer = styled.div`
  display: flex;
  flex: 1 0;
  overflow-y: hidden;
`;

const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  max-width: 400px;
  padding: 1.8rem 2rem 3rem 2rem;
  border-radius: 0.8rem;
  margin: 0 1.6rem;
  text-align: center;
`;

const CloseContainer = styled(StyledTappable)`
  align-self: flex-end;
`;

const ModalIcon = styled.img`
  width: 9.1rem;
`;

const ModalText = styled(RegularText)`
  font-size: 1.6rem;
`;

const ModalTitle = styled(BoldText)`
  font-size: 1.1rem;
  margin: 0.625rem 0rem;
`;

const CloseIcon = styled(FaTimes)`
  font-size: 1.4rem;
`;
const ActionButtons = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  padding-top: 12px;
`
const ActionButton = styled.button`
  background: #8852cc;
  color: white;
  border-radius: 5px;
  font-size: 1.5rem;
  margin-top: 12px;
`
class App extends Component {
  hideModal = () => {
    const { dispatch } = this.props;
    dispatch(hideError());
  };
  hideSuccessModal = () => {
    const { dispatch } = this.props;
    dispatch(hideSuccess());
  };
  hideJoinMessageModal = () => {
    const { dispatch } = this.props;
    dispatch(closeJoinMessage());
  };

  fetchConsults = () => {
    const { dispatch, authToken, joinMessage } = this.props;
    dispatch(fetchConsults({ authToken, searchText: joinMessage.applicationNo, isInsta: 1 }));
  };

  initiateVideoCall = () => {
    const { dispatch } = this.props;
    const isInsta = 1;
    this.fetchConsults()
    setTimeout(() => dispatch(initiateVideoCall(isInsta)), 1000);
  };
  render() {
    return (
      <OuterContainer>
        <Router>
          <>
            <Route path="/" exact component={LoginPage} />
            <Route path="/home" component={DashboardHome} />
            <Route path="/admin" component={MasterAdmin} />
            <Route path="/opsDashboard" component={OpsDashboard} />
            <Route path="/video-call" component={VideoCallContainer} />
            <Route path="/view-sheets" component={ViewSheets} />
          </>
        </Router>
        {this.props.appError.hasError && (
          <ErrorModal>
            <ModalContainer>
              <ModalBody>
                <CloseContainer onTap={this.hideModal}>
                  <CloseIcon />
                </CloseContainer>
                <ModalIcon src={modalIcon} />
                <ModalTitle>{this.props.appError.errorTitle}</ModalTitle>
                <ModalText>{this.props.appError.errorDesc}</ModalText>
              </ModalBody>
            </ModalContainer>
          </ErrorModal>
        )}
        {this.props.appSuccess.hasSuccess && (
          <Modal>
            <ModalContainer>
              <ModalBody>
                <CloseContainer onTap={this.hideSuccessModal}>
                  <FaTimes />
                </CloseContainer>
                <ModalIcon src={modalSuccessIcon} />
                <ModalTitle>{this.props.appSuccess.messageTitle}</ModalTitle>
                <ModalText>{this.props.appSuccess.messageBody}</ModalText>
              </ModalBody>
            </ModalContainer>
          </Modal>
        )}
        {this.props.joinMessage.visible && (
          <Modal>
            <ModalContainer>
              <ModalBody>
                <CloseContainer onTap={this.hideJoinMessageModal}>
                  <FaTimes />
                </CloseContainer>
                <ModalIcon src={modalSuccessIcon} />
                <ModalTitle style={{ fontSize: '2rem' }}>User Joined the Call</ModalTitle>
                <ModalText>
                  Application No - {this.props.joinMessage.applicationNo}
                  <br />
                  User Name - {this.props.joinMessage.userName}
                </ModalText>
                <ActionButtons>
                  <ActionButton className='btn btn-primary' onClick={this.initiateVideoCall}>Join</ActionButton>
                  <ActionButton className='btn btn-primary' onClick={this.hideJoinMessageModal}>Close</ActionButton>
                </ActionButtons>
              </ModalBody>
            </ModalContainer>
          </Modal>
        )}
      </OuterContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  appError: state.app.appError,
  appSuccess: state.app.appSuccess,
  joinMessage: state.app.joinPopup,
  authToken: state.user.authToken,
});

export default connect(mapStateToProps)(App);
