export const defaultState = {
    videoCallInitiated: false
};

export default function videoCall(state = defaultState, action) {
    switch (action.type) {
        case 'INITIATE_VIDEO_CALL':
            return Object.assign({}, state, {
                videoCallInitiated: true,
                isInsta: action.payload && action.payload.isInsta && action.payload.isInsta == 1 ? 1 : 0
            });
        case 'END_VIDEO_CALL':
            return Object.assign({}, state, {
                videoCallInitiated: false,
            });
        default:
            return state;
    }
}
