import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { FaTimes, FaCalendarAlt } from 'react-icons/fa';
import { IoMdRepeat } from 'react-icons/io';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';

import { BlackText, BoldText, RegularText, Text } from './common/Text';
import Modal from '../containers/common/Modal';
import { PrimaryButton } from './common/Buttons';
import { Button as AndtButton } from 'antd';
import visitLogo from '../images/common/visit-logo-white.svg';
import callIcon from '../images/call-icon.svg';
import videoIcon from '../images/video-icon.svg';
import closeIcon from '../images/close-icon.svg';
import StyledTappable from './common/StyledTappable';
import TransferConsultationModal from '../components/consultations/TransferConsultationModal';
import CreateDoctorModal from '../components/CreateDoctorModal';
import CaseSingUp from './CreateCaseSingUp';
import ImportCasesModal from '../components/ImportCasesModal';
import ImportCaseLicModal from './ImportCaseLicModal';
import CloseConsultationModal from '../components/consultations/CloseConsultationModal';
import VoiceCallSuccessModal from '../components/consultations/VoiceCallSuccessModal';
import VideoCallScheduleModal from './VideoCallScheduleModal';

import {
  voiceCallUser,
  goActive,
  goInactive,
  initiateFormMerCall,
  markFollowupUser,
  scheduleCall,
  markCasePartialTele,
  initiateMeeting,
  setAvailableForCallStatus,
  getClosurePercent,
} from '../services';
import {
  logoutUser,
  closeConsult,
  setUserInfo,
  updateConsults,
  callMade,
  resetActiveConsult,
  fetchConsults,
  updateCallId,
} from '../actions';
import AddNewCaseModalNiva from './AddNewCaseModalNiva';
import { Switch } from 'antd';

const OuterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.4rem;
  background: #8852cc;
  position: relative;
`;

const LogoContainer = styled.img`
  max-width: 9.8rem;
`;

const InsurerLogoContainer = styled.img`
  max-width: 9.8rem;
  max-height: 5rem;
  margin-left: 30px;
`;

const PatientDetailText = styled(BlackText)`
  font-size: 1.9rem;
  color: white;
`;

const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ActionContainer = styled(StyledTappable)`
  display: flex;
  align-items: center;
  margin: 0 2.5rem;
`;

const ActionIcon = styled.img`
  max-height: 1.5rem;
  margin-right: 1rem;
`;

const TransferIcon = styled(IoMdRepeat)`
  font-size: 1.5rem;
  color: white;
  margin-right: 1rem;
`;

const ActionText = styled(BoldText)`
  font-size: 1.4rem;
  color: white;
`;

const UserContainer = styled(StyledTappable)`
  display: flex;
  align-items: center;
  margin-left: 10px;
`;

const UserText = styled(BoldText)`
  font-size: 1.6rem;
  color: white;
`;

const OptionContainer = styled(StyledTappable)`
  flex-direction: row;
  justify-content: flex-start;
  padding-left: 1.25rem;
  z-index: 999;
  /* align-items: flex-start; */
  &:hover {
    background: rgba(125, 125, 125, 0.3);
  }
`;

const AttachmentText = styled(RegularText)`
  margin: 0.625rem;
  font-size: 1.6rem;
  /* z-index: 999; */
`;

const AttachmentContainer = styled.div`
  position: absolute;
  width: 20rem;
  flex: 1 0;
  top: 5rem;
  right: 2rem;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  z-index: 999;
  overflow: visible;

  &:focus {
    border: none;
    outline: none;
  }
`;

const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`;

const ModalBody = styled.div`
  display: flex;
  flex: 1;
  max-width: 35%;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 1.125rem 1.25rem 1.875rem 1.25rem;
  border-radius: 0.5rem;
  margin: 0 1rem;
  text-align: center;
`;

const CloseContainer = styled(StyledTappable)`
  /* align-self: flex-end; */
`;

const CrossIcon = styled(FaTimes)`
  font-size: 2rem;
  font-weight: normal;
`;

const ButtonComponent = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 1.625rem;
  margin-bottom: 1.625rem;
  align-items: center;
  justify-content: space-between;
`;

const Button = styled(PrimaryButton)`
  margin-left: 0.625rem;
  /* width: 6rem; */
  margin-top: 1.625rem;
`;

const ButtonText = styled(BoldText)`
  color: white;
  font-size: 1.5rem;
  padding: 0.625rem 1.625rem;
  /* text-transform: uppercase; */
  text-align: center;
`;

const ModalHeader = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  padding: 0rem 1.25rem 1.875rem 1.25rem;
`;

const HeaderText = styled(BoldText)`
  font-size: 2rem;
`;

const ButtonsComponent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Body = styled.div`
  display: flex;
  flex: 1;
  align-self: stretch;
  /* border: 4mm solid rgb(170, 50, 20, 0.6); */
  flex-direction: column;
`;

const LabelText = styled(Text)`
  margin-top: 2rem;
  font-size: 1.6rem;
  text-align: center;
  align-self: center;
  margin-bottom: 2rem;
  color: #000;
  display: flex;
  flex-direction: row;
`;

const CalendarIcon = styled(FaCalendarAlt)`
  font-size: 1.5rem;
  color: #fff;
  margin-right: 1rem;
`;
const Container = styled.div`
  display: flex;
`;

const EndCallContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
`;

class DashboardHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showOptions: false,
      alternateCallSection: false,
      showCloseConsultationModal: false,
      showNewDoctorModal: false,
      showTransferConsultationModal: false,
      showImportCasesModal: false,
      voiceCallLoading: false,
      showVoiceCallConfirmModal: false,
      formOpened: false,
      showVideoCallConfirmModal: false,
      showVoiceCallModal: false,
      showFollowupModal: false,
      showAddCaseModal: false,
      showScheduleModal: false,
      followupDate: new Date(),
      ucid: null,
      isSopCallback: false,
      isAlternateCall: false,
      closureCount: '',
      attachmentTypes: [
        { type: 'goHome', name: 'Home', admin: true, showToVendor: true },
        { type: 'goActive', name: 'Go Active' },
        { type: 'goInactive', name: 'Go In-active' },
        { type: 'updateInfo', name: 'Update Info' },
        {
          type: 'goMaster',
          name: 'Master Admin',
          admin: true,
          showToVendor: false,
        },
        {
          type: 'addDoctor',
          name: 'Add Doctor',
          admin: true,
          showToVendor: false,
        },
        {
          type: 'caseSignUp',
          name: 'Add Case',
          admin: false,
          showToVendor: false,
          insurer: 'ipru',
        },
        {
          type: 'max-case-add',
          name: 'Add New Case',
          admin: true,
          showToVendor: false,
          insurer: 'max',
        },
        {
          type: 'opsDashboard',
          name: 'Ops Dashboard',
          admin: true,
          showToVendor: true,
        },
        {
          type: 'importCases',
          name: 'Import Cases',
          admin: true,
          showToVendor: false,
          insurer: 'abhi',
        },
        {
          type: 'importCases',
          name: 'Import Cases',
          admin: true,
          showToVendor: false,
          insurer: 'lic',
        },
        {
          type: 'importCaseLic',
          name: 'Import Direct Case',
          admin: true,
          showToVendor: false,
          insurer: 'lic',
        },
        {
          type: 'importCases',
          name: 'Import Cases',
          admin: true,
          showToVendor: false,
          insurer: 'aegon',
        },
        {
          type: 'importCases',
          name: 'Import Cases',
          admin: true,
          showToVendor: false,
          insurer: 'ipru',
        },
        {
          type: 'importCases',
          name: 'Import Cases',
          admin: true,
          showToVendor: false,
          insurer: 'absli',
        },
        {
          type: 'importCases',
          name: 'Import Cases',
          admin: true,
          doctorIds: [768],
          showToVendor: false,
          insurer: 'absli-vv',
        },
        {
          type: 'importCases',
          name: 'Import Cases',
          admin: true,
          showToVendor: false,
          insurer: 'reliance',
        },
        {
          type: 'importCases',
          name: 'Import Cases',
          admin: true,
          showToVendor: false,
          insurer: 'hdfc',
        },
        {
          type: 'importCases',
          name: 'Import Cases',
          admin: true,
          showToVendor: false,
          insurer: 'kotak',
        },
        {
          type: 'importCases',
          name: 'Import Cases',
          admin: true,
          showToVendor: false,
          insurer: 'tata',
        },
        {
          type: 'importCases',
          name: 'Import Cases',
          admin: true,
          showToVendor: false,
          insurer: 'tata-mer',
        },
        {
          type: 'importCases',
          name: 'Import Cases',
          admin: true,
          showToVendor: false,
          insurer: 'mchi',
        },
        {
          type: 'importCases',
          name: 'Import Cases',
          admin: true,
          showToVendor: false,
          insurer: 'max',
        },
        {
          type: 'viewSheets',
          name: 'View Sheets',
          admin: true,
          showToVendor: false,
          insurer: 'ipru',
        },
        {
          type: 'importCases',
          name: 'Import Cases',
          admin: true,
          showToVendor: false,
          insurer: 'godigit',
        },
        {
          type: 'importCases',
          name: 'Import Cases',
          admin: true,
          showToVendor: false,
          insurer: 'icicilbd',
        },
        { type: 'Logout', name: 'Logout' },
      ],
    };
    this.optionsRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    const { activeConsult } = this.props;
    const previousConsultation = prevProps.activeConsult;
    if (activeConsult && activeConsult !== previousConsultation) {
      this.setState({
        formOpened: false,
        alternateCallSection: false,
      });
      return;
    }
  }

  componentDidMount() {
    this.getClosureCount();
  }

  getClosureCount = () => {
    getClosurePercent().then((resp) => {
      console.log(resp);
      this.setState({
        closureCount: resp.dailyCaseCompletionPercentage,
      });
    });
  };

  confirmVoiceCall = () => {
    this.setState({ showVoiceCallConfirmModal: true });
  };
  confirmVideoCall = () => {
    this.setState({ showVideoCallConfirmModal: true });
  };

  initiateVoiceCall = (isAlternate = false) => {
    const { dispatch, doctorType } = this.props;
    const { userId } = this.props.activeConsult.user;
    this.setState({ voiceCallLoading: true });
    if (doctorType === 'mbbs') {
      initiateFormMerCall(userId, isAlternate)
        .then((result) => {
          console.log({ result }, 'voice call initiated');
          this.setState({
            showVoiceCallConfirmModal: false,
            showVoiceCallModal: true,
            ucid: result.ucid,
            isAlternateCall: isAlternate,
            isSopCallback: false,
          });
          dispatch(callMade(true));
        })
        .catch((err) => {
          console.log({ err });
        })
        .finally(() => {
          this.setState({
            showVoiceCallConfirmModal: false,
            voiceCallLoading: false,
            isSopCallback: false,
          });
        });
    } else {
      voiceCallUser(
        this.props.authToken,
        this.props.activeConsult.consultationId,
        userId,
      )
        .then((result) => {
          console.log({ result }, 'voice call initiated');
          this.setState({
            showVoiceCallConfirmModal: false,
            showVoiceCallModal: true,
            ucid: result.ucid,
            isAlternateCall: isAlternate,
          });
        })
        .catch((err) => {
          console.log({ err });
        })
        .finally(() => {
          this.setState({
            showVoiceCallConfirmModal: false,
            voiceCallLoading: false,
          });
        });
    }
  };

  openFormLink = () => {
    const { activeConsult } = this.props;
    const link = activeConsult.merLink;
    const anchor = document.createElement('a');
    anchor.setAttribute('href', link);
    anchor.setAttribute('target', '_blank');
    anchor.click();
    this.setState({
      formOpened: true,
    });
  };

  markCasePartialTele = () => {
    const { activeConsult } = this.props;
    this.setState({ voiceCallLoading: true });
    const body = {
      userId: activeConsult.user.userId,
    };
    markCasePartialTele(body)
      .then(() => {
        this.hideModal();
        this.fetchConsults();
        this.props.dispatch(resetActiveConsult());
      })
      .catch(() => {})
      .finally(() => {
        this.setState({ voiceCallLoading: false });
      });
  };

  openAttachmentType = (ele, idx) => {
    console.log({ ele, idx });
    const { dispatch, history, doctorId } = this.props;
    switch (ele.type) {
      case 'goHome':
        history.push('/home');
        break;
      case 'goMaster':
        history.push('/admin');
        break;
      case 'updateInfo':
        this.props.showUpdateInfoModal();
        break;
      case 'goActive':
        this.setState({
          showModal: true,
          showModalLoading: true,
          showOptions: false,
        });
        goActive(doctorId)
          .then(() => {
            dispatch(
              setUserInfo({
                active: true,
              }),
            );
          })
          .catch((err) => {
            const errorMessage =
              err.response &&
              err.response.data &&
              err.response.data.errorMessage
                ? err.response.data.errorMessage
                : 'Something went wrong. Please try again later';
            this.setState({ showModalLoading: false, modalText: errorMessage });
            console.error(err);
          });
        break;
      case 'goInactive':
        this.setState({
          showModal: true,
          showModalLoading: true,
          showOptions: false,
        });
        goInactive(doctorId)
          .then(() => {
            dispatch(
              setUserInfo({
                active: false,
              }),
            );
          })
          .catch((err) => {
            const errorMessage =
              err.response &&
              err.response.data &&
              err.response.data.errorMessage
                ? err.response.data.errorMessage
                : 'Something went wrong. Please try again later';
            this.setState({ showModalLoading: false, modalText: errorMessage });
            console.error(err);
          });
        break;
      case 'Logout':
        this.setState({ showOptions: false });
        goInactive(doctorId)
          .then(() => {
            this.props.dispatch(logoutUser());
            dispatch(
              setUserInfo({
                active: false,
              }),
            );
          })
          .catch((err) => {
            const errorMessage =
              err.response &&
              err.response.data &&
              err.response.data.errorMessage
                ? err.response.data.errorMessage
                : 'Something went wrong. Please try again later';
            this.setState({ showModalLoading: false, modalText: errorMessage });
            console.error(err);
          });
        this.props.history.push('/');
        break;
      case 'addDoctor':
        this.setState({
          showOptions: false,
          showNewDoctorModal: true,
        });
        break;
      case 'caseSignUp':
        this.setState({
          showOptions: false,
          showAddCaseModal: true,
        });
        break;
      case 'max-case-add':
        this.setState({
          showOptions: false,
          showAddCaseModal: true,
        });
        break;
      case 'importCases':
        this.setState({
          showOptions: false,
          showImportCasesModal: true,
        });
        break;
      case 'importCaseLic':
        this.setState({
          showOptions: false,
          showImportCaseLicModal: true,
        });
        break;
      case 'opsDashboard':
        this.props.history.push('/opsDashboard');
        break;
      case 'home':
        this.props.history.push('/home');
        break;
      case 'viewSheets':
        this.props.history.push('/view-sheets');
      default:
        break;
    }
  };

  showOptions = () => {
    this.setState({ showOptions: !this.state.showOptions }, () => {
      this.optionsRef.current && this.optionsRef.current.focus();
    });
  };

  initiateCloseConsultation = () => {
    this.setState({
      showCloseConsultationModal: true,
    });
  };

  fetchConsults() {
    const { dispatch, activeFilterName, authToken } = this.props;
    dispatch(
      fetchConsults({
        authToken,
        filters: [{ name: activeFilterName, isSelected: true }],
      }),
    );
  }

  hideModal = () => {
    this.setState({
      showCloseConsultationModal: false,
      showTransferConsultationModal: false,
      showVoiceCallModal: false,
      showVoiceCallConfirmModal: false,
      showFollowupModal: false,
      showNewDoctorModal: false,
      showAddCaseModal: false,
      showImportCasesModal: false,
      showVideoCallConfirmModal: false,
      showScheduleModal: false,
      videoCallLoading: false,
      ucid: null,
      isSopCallback: false,
      isAlternateCall: false,
      alternateCallSection: false,
      showImportCaseLicModal: false,
    });
  };

  closeConsult = (reason) => {
    console.log({ reason });
    const { userId, consultationId, authToken, dispatch } = this.props;
    dispatch(closeConsult(userId, consultationId, reason, authToken));
    this.hideModal();
  };

  closeOptions = () => {
    this.setState({
      showOptions: false,
    });
  };

  initiateTransferConsultation = () => {
    this.setState({ showTransferConsultationModal: true });
  };

  confirmFollowup = () => {
    this.setState({
      showFollowupModal: true,
      followupDate: moment().add(60, 'mins').toDate(),
    });
  };

  onFollowupDateChanged = (date) => {
    this.setState({
      followupDate: date,
    });
  };

  setFollowup = () => {
    const { activeConsult, dispatch } = this.props;
    const { followupDate } = this.state;
    this.setState({ voiceCallLoading: true });
    markFollowupUser(
      activeConsult.user.userId,
      moment(followupDate).format('YYYY-MM-DD HH:mm:ss'),
    )
      .then(() => {
        this.hideModal();
        dispatch(updateConsults());
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({ voiceCallLoading: false });
      });
  };

  setSchedule = () => {
    const { user, activeConsult, doctorId } = this.props;
    if (activeConsult.scheduleTime) {
      return this.props.initiateVideoCall();
    }
    this.setState({ videoCallLoading: true });

    let newScheduleDate = new Date();
    newScheduleDate = new Date(
      newScheduleDate.setHours(newScheduleDate.getHours() + 5),
    );
    newScheduleDate = newScheduleDate.setMinutes(
      newScheduleDate.getMinutes() + 30,
    );
    /*
       scheduleCall(body)
     .then((res) => {
       if (res.callId) {
         this.props.dispatch(updateCallId(res.callId));
       }
       this.props.updateData();
       this.setState({
         showVideoCallConfirmModal: true,
         videoCallLoading: false,
       });
     })
   }) */

    /*
        scheduleCall(body)
      .then((res) => {
        if (res.callId) {
          this.props.dispatch(updateCallId(res.callId));
        }
        this.props.updateData();
        this.setState({
          showVideoCallConfirmModal: true,
          videoCallLoading: false,
        });
      })
    }) */
    console.log(this.props);
    console.log(this.props);
    const body = {
      scheduleTime: new Date(newScheduleDate),
      scheduleBy: doctorId,
      consultationId: activeConsult.consultationId,
    };
    console.log(body);
    scheduleCall(body)
      .then((res) => {
        if (res.callId) {
          this.props.dispatch(updateCallId(res.callId));

          this.props.updateData();

          setTimeout(() => {
            this.setState({
              videoCallLoading: false,
            });
            return this.props.initiateVideoCall();
          }, 300);

          //setTimeout(()=>)
          //return;
        } else {
          this.setState({
            videoCallLoading: false,
          });
          this.fetchConsults();
          this.props.dispatch(resetActiveConsult());
        }
      })
      .catch((err) => {
        console.log('Dyte Error', err);
        this.setState({ videoCallLoading: false });
      });
  };

  getInsurerLogo = () => {
    const insurer = this.props.insurer;
    if (!insurer) {
      return null;
    }
    switch (insurer) {
      case 'abhi':
        return 'https://visit-public.s3.ap-south-1.amazonaws.com/mer/abhi-insurance.jpg';
      case 'max':
        return 'https://visit-public.s3.ap-south-1.amazonaws.com/mer/max.png';
      case 'ipru':
        return 'https://visit-public.s3.ap-south-1.amazonaws.com/mer/ipru-logo.png';
      case 'aegon':
        return 'https://visit-public.s3.ap-south-1.amazonaws.com/mer/bandhan-life.png';
      case 'lic':
        return 'https://visit-public.s3.ap-south-1.amazonaws.com/assets/lic_logo.jpg';
      case 'hehi':
        return 'https://www.hdfcergo.com/images/default-source/home/logo_hdfc.svg';
      case 'reliance':
        return 'https://visit-public.s3.ap-south-1.amazonaws.com/mer/reliance_insurance_logo.png';
      case 'absli':
        return 'https://visit-public.s3.ap-south-1.amazonaws.com/assets/absli-logo.png';
      case 'absli-vv':
        return 'https://visit-public.s3.ap-south-1.amazonaws.com/assets/absli-logo.png';
      case 'mchi':
        return 'https://visit-public.s3.ap-south-1.amazonaws.com/mer/manipalcigna+health+insurance.jpeg';
      case 'godigit':
        return 'https://visit-public.s3.ap-south-1.amazonaws.com/mer/godigit-insurance-logo.png';
      case 'icicilbd':
        return 'https://visit-public.s3.ap-south-1.amazonaws.com/icici-lombard/lombard-logo.jpeg';
      default:
        return null;
    }
  };
  sopCallback = () => {
    if (this.props.activeConsult && this.props.activeConsult.altNumber) {
      this.setState({
        showVoiceCallConfirmModal: true,
        showVoiceCallModal: false,
        alternateCallSection: true,
      });
    } else {
      this.setState({
        showVoiceCallModal: false,
        alternateCallSection: false,
      });
    }
  };
  setSopCallback = (val) => {
    this.setState({
      isSopCallback: val,
    });
  };

  render() {
    const {
      active,
      activeConsult,
      admin,
      doctorFirstName,
      doctorType,
      insurer,
      qc,
      vendorId,
      doctorId,
      isDoctorAvailableForCall,
      instantTele,
      inboundText,
    } = this.props;
    const {
      followupDate,
      showImportCasesModal,
      showFollowupModal,
      showNewDoctorModal,
      showAddCaseModal,
      showScheduleModal,
      isSopCallback,
      isAlternateCall,
      alternateCallSection,
      closureCount,
      showImportCaseLicModal,
    } = this.state;
    const now = new Date();
    now.setSeconds(0);
    now.setMilliseconds(0);

    const renderAttachmentTypes = (ele, idx) => {
      if (ele.admin && !admin) {
        if (ele.doctorIds && ele.doctorIds.includes(doctorId) === true) {
        } else {
          return false;
        }
      }
      if (ele.doctor && qc) {
        return false;
      }
      if (ele.insurer && ele.insurer !== insurer) {
        return false;
      }
      if (!ele.showToVendor && ele.admin && vendorId) {
        return false;
      }
      if (
        !ele.showToVendor &&
        ele.admin &&
        [651, 281, 650].includes(doctorId)
      ) {
        return false;
      }
      if (this.props.history.location.pathname.replace('/', '') === ele.type) {
        return false;
      }
      return (
        <OptionContainer
          key={ele.name}
          onClick={() => this.openAttachmentType(ele, idx)}
        >
          <AttachmentText>{ele.name}</AttachmentText>
        </OptionContainer>
      );
    };
    return (
      <OuterContainer>
        <div>
          <LogoContainer src={visitLogo} />
          {this.getInsurerLogo() && (
            <InsurerLogoContainer src={this.getInsurerLogo()} />
          )}
        </div>
        {activeConsult.consultationId && (
          <PatientDetailText>
            {`${activeConsult.user.userName}(${activeConsult.user.userId})`}
          </PatientDetailText>
        )}
        {activeConsult.consultationId &&
          this.props.history.location.pathname !== '/ops' && (
            <ActionsContainer>
              <ActionContainer onTap={this.confirmVoiceCall}>
                <ActionIcon src={callIcon} />
                <ActionText>Phone</ActionText>
              </ActionContainer>

              {activeConsult.callType === 'video' && (
                <ActionContainer onTap={this.confirmVideoCall}>
                  <ActionIcon src={videoIcon} />
                  <ActionText>Video Call</ActionText>
                </ActionContainer>
              )}

              {doctorType === 'mbbs' ? (
                <ActionContainer onTap={() => this.confirmFollowup()}>
                  <CalendarIcon />
                  <ActionText>Set Followup</ActionText>
                </ActionContainer>
              ) : (
                <ActionContainer onTap={() => this.initiateCloseConsultation()}>
                  <ActionIcon src={closeIcon} />
                  <ActionText>Close Consultation</ActionText>
                </ActionContainer>
              )}
              {admin && !qc && (
                <ActionContainer onTap={() => this.initiateCloseConsultation()}>
                  <ActionIcon src={closeIcon} />
                  <ActionText>Close Consultation</ActionText>
                </ActionContainer>
              )}
              {admin && (
                <ActionContainer
                  onTap={() => this.initiateTransferConsultation()}
                >
                  <TransferIcon />
                  <ActionText>Transfer Case</ActionText>
                </ActionContainer>
              )}
            </ActionsContainer>
          )}
        {closureCount && admin && !qc ? (
          <UserText>Same Day Closure: {closureCount}%</UserText>
        ) : (
          ''
        )}
        <Container>
          {inboundText && <UserText>{inboundText}</UserText>}

          <UserContainer onTap={this.showOptions}>
            <UserText>
              {`${doctorFirstName}`}
              {active ? (
                <BlackText color="#42cc3b">Active</BlackText>
              ) : (
                <BlackText>Inactive</BlackText>
              )}
            </UserText>
          </UserContainer>
        </Container>
        {this.state.showOptions && (
          <AttachmentContainer
            tabIndex="1"
            onBlur={this.closeOptions}
            ref={this.optionsRef}
          >
            {this.state.attachmentTypes.map(renderAttachmentTypes)}
          </AttachmentContainer>
        )}
        {this.state.showTransferConsultationModal && (
          <TransferConsultationModal hideModal={this.hideModal} />
        )}
        {this.state.showCloseConsultationModal && (
          <CloseConsultationModal
            closeConsult={(body) => this.closeConsult(body)}
            hideModal={() => this.hideModal()}
          />
        )}
        {showFollowupModal && (
          <Modal>
            <ModalContainer>
              <ModalBody>
                <ModalHeader>
                  <HeaderText>Schedule Followup</HeaderText>
                  <ButtonsComponent>
                    <CloseContainer onTap={this.hideModal}>
                      <CrossIcon />
                    </CloseContainer>
                  </ButtonsComponent>
                </ModalHeader>

                <Body>
                  <LabelText>
                    {`Set a time to followup with ${activeConsult.user.userName}`}
                  </LabelText>
                  <DatePicker
                    selected={followupDate}
                    minDate={now}
                    minTime={
                      new Date().getDate() ===
                      new Date(this.state.followupDate || new Date()).getDate()
                        ? new Date().setMinutes(new Date().getMinutes() + 6)
                        : new Date().setHours(6, 0, 0)
                    }
                    maxTime={new Date().setHours(21, 59, 59)}
                    onChange={this.onFollowupDateChanged}
                    showTimeSelect
                    timeFormat="hh:mm a"
                    timeIntervals={15}
                    dateFormat="yyyy-MM-dd HH:mm"
                  />
                  <ButtonComponent>
                    <Button
                      onTap={this.setFollowup}
                      loading={this.state.voiceCallLoading}
                    >
                      <ButtonText>Confirm</ButtonText>
                    </Button>
                  </ButtonComponent>
                </Body>
              </ModalBody>
            </ModalContainer>
          </Modal>
        )}
        {showNewDoctorModal && (
          <CreateDoctorModal hideModal={() => this.hideModal()} />
        )}
        {showAddCaseModal ? (
          insurer === 'max' ? (
            <AddNewCaseModalNiva hideModal={() => this.hideModal()} />
          ) : (
            <CaseSingUp hideModal={() => this.hideModal()} />
          )
        ) : (
          ''
        )}

        {showImportCasesModal && (
          <ImportCasesModal hideModal={() => this.hideModal()} />
        )}
        {showImportCaseLicModal && (
          <ImportCaseLicModal hideModal={() => this.hideModal()} />
        )}
        {this.state.showVoiceCallConfirmModal && (
          <Modal>
            <ModalContainer>
              <ModalBody>
                <ModalHeader>
                  <HeaderText>
                    {insurer === 'ipru' ? 'Important' : 'Voice Call'}
                  </HeaderText>
                  {!alternateCallSection && (
                    <ButtonsComponent>
                      <CloseContainer onTap={this.hideModal}>
                        <CrossIcon />
                      </CloseContainer>
                    </ButtonsComponent>
                  )}
                </ModalHeader>

                <Body>
                  <LabelText>
                    {insurer === 'ipru' &&
                    activeConsult &&
                    activeConsult.merLink &&
                    activeConsult.merLink !== 'https://www.google.com' ? (
                      this.state.formOpened ? (
                        <strong>
                          Please Check the form and mark the case as Partial
                          Tele If Mentioned in IPRU FORM. <br /> Else Initiate
                          the Call
                        </strong>
                      ) : (
                        <strong>
                          {' '}
                          Please make sure to open the Form Before Initiating
                          the call
                        </strong>
                      )
                    ) : alternateCallSection ? (
                      'Please call on the alternate number'
                    ) : (
                      `Are you sure you want to initiate voice call with ${activeConsult.user.userName}?`
                    )}
                  </LabelText>
                  <ButtonComponent>
                    {insurer === 'ipru' &&
                    activeConsult &&
                    activeConsult.merLink &&
                    activeConsult.merLink !== 'https://www.google.com' ? (
                      this.state.formOpened ? (
                        <>
                          <Button
                            onTap={this.markCasePartialTele}
                            loading={this.state.voiceCallLoading}
                          >
                            <ButtonText>Mark Case Partial Tele</ButtonText>
                          </Button>
                          <Button
                            onTap={() => this.initiateVoiceCall()}
                            loading={this.state.voiceCallLoading}
                          >
                            <ButtonText>Call Now</ButtonText>
                          </Button>
                        </>
                      ) : (
                        <Button onTap={this.openFormLink}>
                          <ButtonText>Open Form</ButtonText>
                        </Button>
                      )
                    ) : (
                      <div>
                        {!isSopCallback && (
                          <Button
                            onTap={() => this.initiateVoiceCall()}
                            loading={this.state.voiceCallLoading}
                          >
                            <ButtonText>Call Now</ButtonText>
                          </Button>
                        )}

                        {['max', 'mchi', 'reliance', 'icicilbd'].includes(
                          insurer,
                        ) ? (
                          <Button
                            onTap={() => this.initiateVoiceCall(true)}
                            loading={this.state.voiceCallLoading}
                          >
                            <ButtonText>Call Alternate Number</ButtonText>
                          </Button>
                        ) : (
                          ''
                        )}
                      </div>
                    )}
                  </ButtonComponent>
                  {false ? (
                    <EndCallContainer>
                      <AndtButton
                        type="primary"
                        danger
                        onClick={() => {
                          this.hideModal();
                        }}
                      >
                        Call Complete
                      </AndtButton>
                    </EndCallContainer>
                  ) : (
                    ''
                  )}
                </Body>
              </ModalBody>
            </ModalContainer>
          </Modal>
        )}
        {this.state.showVideoCallConfirmModal && (
          <Modal>
            <ModalContainer>
              <ModalBody>
                <ModalHeader>
                  <HeaderText>Video Call</HeaderText>
                  <ButtonsComponent>
                    <CloseContainer onTap={this.hideModal}>
                      <CrossIcon />
                    </CloseContainer>
                  </ButtonsComponent>
                </ModalHeader>

                <Body>
                  <LabelText>
                    {`Schedule a new call with ${activeConsult.user.userName}`}
                    {activeConsult.scheduleTime &&
                      ` or join the call scheduled at ${new Date(
                        activeConsult.scheduleTime,
                      ).toLocaleString()}`}
                  </LabelText>
                  <ButtonComponent>
                    <Button
                      onTap={() => {
                        this.hideModal();
                        this.setSchedule();
                        // this.props.initiateVideoCall();
                      }}
                      loading={this.state.videoCallLoading}
                    >
                      <ButtonText>Join Now</ButtonText>
                    </Button>
                    <Button
                      onTap={() => {
                        this.setState({
                          showScheduleModal: true,
                        });
                      }}
                      loading={this.state.videoCallLoading}
                    >
                      <ButtonText>Schedule for Later</ButtonText>
                    </Button>
                  </ButtonComponent>
                </Body>
              </ModalBody>
            </ModalContainer>
          </Modal>
        )}
        {this.state.showVoiceCallModal && (
          <VoiceCallSuccessModal
            ucid={this.state.ucid}
            sopCallback={this.sopCallback}
            isSopCallback={this.state.isSopCallback}
            setSopCallback={this.setSopCallback}
            activeConsult={activeConsult}
            hideModal={() => this.hideModal()}
            isAlternateCall={this.state.isAlternateCall}
          />
        )}
        {showScheduleModal && (
          <VideoCallScheduleModal
            hideModal={() => this.hideModal()}
            updateData={() => {
              console.log('calling dispatch');

              //this.props.dispatch(updateConsults());
              this.props.dispatch(resetActiveConsult());
              this.fetchConsults();
              // dispatch(updateConsults());
            }}
          />
        )}
      </OuterContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  userId: state.chat.receiverId,
  activeConsult: state.consults.activeConsult,
  consultationId: state.consults.activeConsult.consultationId,
  doctorFirstName: state.user.doctorFirstName,
  doctorId: state.user.doctorId,
  doctorType: state.user.doctorType,
  authToken: state.user.authToken,
  active: state.user.active,
  admin: state.user.admin,
  insurer: state.user.insurer,
  qc: state.user.qc,
  consults: state.consults.consults,
  vendorId: state.user.vendorId,
  isDoctorAvailableForCall: state.user.isDoctorAvailableForCall,
  instantTele: state.user.instantTele,
  inboundText: state.consults.inboundText,
});

export default withRouter(connect(mapStateToProps)(DashboardHeader));
