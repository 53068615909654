import PubNub from 'pubnub';
import { store } from '../store/index';
import config from '../config';
import { closeJoinMessage, openJoinMessage, showSuccess, userStatus } from '../actions/index';

class AppClient {
  constructor(pubnub) {
    this.pubnub = pubnub;
  }

  subscribe(channelId, insurer) {
    this.pubnub.subscribe({
      channels: [channelId],
      // channelGroups: [channelId],
    });

    console.log('subscribed to app channel', { channelId });
    this.messageListener = this.addMessageListener((payload, channelName) => {
      console.log((channelName), (channelId));
      if ((channelName) === (channelId)) {
        console.log(payload);
        if (payload.name === 'join_message_open') {
          store.dispatch(openJoinMessage(payload.applicationNo, payload.userName))
          if (window.newCaseAudioBuffer && window.audioContext) {
            const soundSource = window.audioContext.createBufferSource();
            soundSource.buffer = window.newCaseAudioBuffer;
            soundSource.connect(window.audioContext.destination);
            soundSource.start();
          }
        }
        if (payload.name === 'join_message_close') {
          store.dispatch(closeJoinMessage())
        }
      }
    });
  }

  unsubscribe(channelId) {
    console.log(
      this.pubnub.unsubscribe({
        channels: [channelId],
      }),
    );
    this.removeListener(this.messageListener);
  }

  addMessageListener(fnc) {
    const listener = {
      message: (m) => {
        console.log({ m }, 'new message');
        const channelName = (m.channel); // The channel for which the message belongs

        const payload = m.message; // The Payload

        fnc(payload, channelName, '', '');
        return;
      },
    };

    this.pubnub.addListener(listener);
    console.log(this.pubnub);
    return listener;
  }

  removeListener(fnc) {
    this.pubnub.removeListener(fnc);
  }

  publish(sessionId) {
    console.log(sessionId);

    this.pubnub.publish(
      {
        message: {
          name: 'client_joined',
          sessionId: sessionId,
        },
        channel: sessionId,
      },
      (status, response) => {
        if (status.error) {
          // Handle error
          console.log(status);
          return;
        }
        console.log(status, response);
      },
    );
  }
}

let client;

const initializeAppClient = () => {
  if (client) {
    return client;
  } else {
    const { user } = store.getState();
    const pubnubParams = {
      subscribeKey: config.pubnubSubscribeKey,
      publishKey: config.pubnubPublishKey,
      authKey: user.doctorUuid,
      uuid: `browser::${user.doctorUuid}`,
    };

    const pubnub = new PubNub(pubnubParams);

    client = new AppClient(pubnub);
    return client;
  }
};

export { initializeAppClient };