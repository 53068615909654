import {
  all,
  apply,
  call,
  put,
  takeLatest,
  takeEvery,
} from 'redux-saga/effects';
import {
  verifyUser as loginUserService,
  loginUser as loginUserVerifyService,
  fetchConsults as fetchConsultsService,
  fetchConsultsOnPagination as fetchConsultsOnPaginationService,
  closeConsultation,
} from '../services';

import { store } from '../store/index';
import { initializeChatClient } from '../services/chatClient';
import {
  setAuthToken,
  setUserInfo,
  setConsults,
  addConsults,
  selectConsult as selectConsultAction,
  setActiveConsult,
  resetChat,
  resetPrescription,
  setChatInfo,
  fetchConsults as fetchConsultsAction,
  setUnrepliedChannel,
  setFetchConsultsUrlForPagination,
  resetFilter,
  setCallRemarks,
  setLoading,
} from '../actions';

/**
 * Login
 */
function* verifyUser({ payload }) {
  const { phone, otp, history } = payload;
  try {
    const result = yield call(loginUserService, phone, otp);
    const { authorization } = result.headers;
    const { doctor, inboundChannel, callRemarksOptions } = result.body;
    yield put(setAuthToken(authorization));
    yield put(
      setUserInfo({
        doctorId: doctor.id,
        doctorPhone: doctor.phone,
        doctorFirstName: doctor.name,
        doctorUuid: doctor.uuid,
        doctorType: doctor.type,
        insurer: doctor.insurer,
        admin: doctor.admin === 1,
        active: doctor.active === 1,
        qc: doctor.qc === 1,
        armsoft: doctor.armsoft === 1,
        changeInsurer: doctor.changeInsurer === 1,
        panNo: doctor.panNo,
        language: doctor.languages,
        email: doctor.email,
        vendorId: doctor.vendorId,
        updateTarget: doctor.updateTarget === 1,
      }),
    );
    if (doctor.admin) {
      yield put(resetFilter());
    }
    if (callRemarksOptions) {
      yield put(setCallRemarks(callRemarksOptions));
    }
    yield put(setUnrepliedChannel(inboundChannel));
    yield call(history.push, '/home');
  } catch (err) {
    console.log(err);
  }
}

function* loginUser({ payload }) {
  const { phone, password, history } = payload;
  try {
    const result = yield call(loginUserVerifyService, phone, password, false);
    const { authorization } = result.headers;
    const { doctor, inboundChannel, callRemarksOptions } = result.body;
    yield put(setAuthToken(authorization));
    yield put(
      setUserInfo({
        doctorId: doctor.id,
        doctorPhone: doctor.phone,
        doctorFirstName: doctor.name,
        doctorUuid: doctor.uuid,
        doctorType: doctor.type,
        insurer: doctor.insurer,
        admin: doctor.admin === 1,
        active: doctor.active === 1,
        qc: doctor.qc === 1,
        armsoft: doctor.armsoft === 1,
        changeInsurer: doctor.changeInsurer === 1,
        panNo: doctor.panNo,
        language: doctor.languages,
        email: doctor.email,
        vendorId: doctor.vendorId,
        updateTarget: doctor.updateTarget === 1,
        isDoctorAvailableForCall: doctor.availableForCall === 1,
        instantTele: doctor.instantTele === 1,
      }),
    );
    if (doctor.admin) {
      yield put(resetFilter());
    }
    if (callRemarksOptions) {
      yield put(setCallRemarks(callRemarksOptions));
    }
    yield put(setUnrepliedChannel(inboundChannel));
    yield call(history.push, '/home');
  } catch (err) {
    console.log(err);
  }
}

function* fetchConsults({ payload }) {
  const {
    authToken,
    filters,
    selectedSponsorId,
    searchText,
    doctorId,
    applicationIds,
    isInsta
  } = payload;
  const { user, info } = store.getState();
  try {
    store.dispatch(setLoading(true));
    const result = yield call(
      fetchConsultsService,
      authToken,
      filters,
      selectedSponsorId,
      searchText,
      null,
      doctorId ? doctorId : info.filterDoctorId,
      applicationIds,
      isInsta
    );
    const { consultations } = result.responseBody;
    yield put(setFetchConsultsUrlForPagination(result.responseURL));
    yield put(setConsults(consultations));
    if (consultations.length > 0 && !user.admin) {
      yield put(selectConsultAction(consultations[0]));
    }
  } catch (err) {
    console.log(err);
  } finally {
    store.dispatch(setLoading(false));
  }
}

function* fetchConsultsOnUpdate() {
  const { user, info } = store.getState();
  const { authToken } = user;
  console.log({ user, authToken }, 'fetchConsultsOnUpdate called');
  try {
    const result = yield call(
      fetchConsultsService,
      authToken,
      [
        {
          name: info.activeFilterName,
          isSelected: true,
        },
      ],
      null,
      info.searchText ? info.searchText : null,
    );
    const { consultations } = result.responseBody;
    yield put(setFetchConsultsUrlForPagination(result.responseURL));
    yield put(setConsults(consultations));
  } catch (err) {
    console.log(err);
  }
}

function* fetchConsultsOnPagination({ payload }) {
  const {
    authToken,
    paginateToken,
    fetchConsultsUrlForPagination,
    applicationIds,
  } = payload;
  try {
    const result = yield call(
      fetchConsultsOnPaginationService,
      authToken,
      paginateToken,
      fetchConsultsUrlForPagination,
      applicationIds,
    );
    const { consultations } = result;
    yield put(addConsults(consultations));
  } catch (err) {
    console.log(err);
  }
}

function* selectConsult({ payload }) {
  const { user: doctor } = store.getState();
  const { consult } = payload;
  const { consultationId, channel, user } = consult;
  try {
    yield put(setActiveConsult(consult));
    yield put(resetChat());
    yield put(resetPrescription());
    if (doctor && doctor.insurer === 'max' && false) {
      console.log('user', user, consult);
      yield put(
        setChatInfo({
          consultationId: consult.chatConsultId,
          chatChannel: channel,
          // unrepliedChannel: `visit-inbound-doctor-${doctor.doctorId}`,
          receiverId: parseInt(channel.split('-')[1]),
        }),
      );
    } else {
      yield put(
        setChatInfo({
          consultationId,
          chatChannel: channel,
          // unrepliedChannel: `visit-inbound-doctor-${doctor.doctorId}`,
          receiverId: user.userId,
        }),
      );
    }

    if (channel) {
      const chatClient = initializeChatClient();
      chatClient.history({
        channel,
        count: 20,
      });
    }
  } catch (err) {
    console.log(err);
  }
}

function* sendMessage({ payload }) {
  const { chatMessage } = payload;
  const chatClient = initializeChatClient();
  yield apply(chatClient, 'publish', [chatMessage]);
}

function* setTypingState({ payload }) {
  const { status } = payload;
  const { user } = store.getState();
  const chatClient = initializeChatClient();
  yield apply(chatClient, 'setState', [status, user.doctorId]);
}

function* closeConsult({ payload }) {
  const { userId, consultationId, reason, authToken } = payload;
  const { info } = store.getState();
  try {
    yield call(closeConsultation, authToken, consultationId, userId, reason);
    const chatClient = initializeChatClient();
    // Send a message when a consultation is closed to update the list
    yield apply(chatClient, 'publishOpsMessage', [
      {
        type: 'ops',
      },
    ]);
    yield put(
      fetchConsultsAction({
        authToken,
        filters: [
          {
            name: info.activeFilterName,
            isSelected: true,
          },
        ],
      }),
    );
  } catch (err) {
    console.log(err);
  }
}

/**
 * User Sagas
 */
export default function* root() {
  yield all([
    takeLatest('LOGIN_USER', loginUser),
    takeLatest('VERIFY_USER', verifyUser),
    takeLatest('FETCH_CONSULTS', fetchConsults),
    takeLatest('UPDATE_CONSULTS', fetchConsultsOnUpdate),
    takeLatest('FETCH_CONSULTS_ON_PAGINATION', fetchConsultsOnPagination),
    takeLatest('SELECT_CONSULT', selectConsult),
    takeLatest('SEND_MESSAGE', sendMessage),
    takeEvery('SET_TYPING_STATE', setTypingState),
    takeEvery('CLOSE_CONSULT', closeConsult),
  ]);
}
